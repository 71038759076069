import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppSettings } from '@src/app/helpers/AppSettings';
import { AuthGuard } from '@src/app/services/Security/guard.service'; 
import { CaracteristicasModule, PermissionModule } from '@src/app/models/General/Permisos';
import { CitasCanceladasInasistentesComponent } from './components/citas-canceladas-inasistentes.component';

const routes: Routes = [{ path: '', component: CitasCanceladasInasistentesComponent  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CitasCanceladasInasistenteRoutingModule {

 
  //Routing principal
  public static RoutingCitasCanceladasInasistente = {
    path: "citas/informes/citas-canceladas-inasistentes",
    loadChildren: () =>
      import("./citas-canceladas-inasistente.module").then(
        (m) => m.CitasCanceladasInasistentesRouting
      ),
    canActivate: [AuthGuard],
    data: {
      title: `Citas Canceladas Inasistente `,
      icon: "fa fa-file",
      idCaracteristica:CaracteristicasModule.CitasCanceladasInasistentes,
      idRecurso:PermissionModule.Citas
    },
  };

}
