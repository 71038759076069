import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";

import { PrintButtonComponent } from "@src/app/components/Core/components/forms/report-forms-viewer/report-forms-viewer.component";
import {
  ReportModel,
  ReportModule,
} from "@src/app/models/Report/report-setting";
import {
  TegettFormControl, TypeField,
} from "@src/app/models/Tegett/tegettFormControl";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ProcessType, SPlusDialogSettings, SPlusFormsDialogsService, SPlusOption, SPlusProcessMessageComponent } from '@saludplus/forms';
import { differenceInDays } from "date-fns";
import { IntlService } from "@progress/kendo-angular-intl";
import { CaracteristicasModule, PermissionModule } from '@src/app/models/General/Permisos';
import { AuthenticationService } from "@src/app/services/Security/authentication.service";


import { NGXLogger } from 'ngx-logger';
import { SplusLogger } from "@src/app/services/Utilities/splus.logger.service";
import { SplusBaseForm } from "@src/app/models/base/BaseFormComponent";
import { RoutingState } from "@src/app/services/Generals/routing.state.service";

@Component({
  selector: 'app-citas-canceladas-inasistentes',
  templateUrl: './citas-canceladas-inasistentes.component.html',
  styleUrls: ['./citas-canceladas-inasistentes.component.css']
})
export class CitasCanceladasInasistentesComponent extends SplusBaseForm  implements OnInit {
  onSubmit(event: any): void {
    throw new Error("Method not implemented.");
  }
  SaveRecord(): void {
    throw new Error("Method not implemented.");
  }
  UpdateRecord(): void {
    throw new Error("Method not implemented.");
  }
  DeleteRecord(): void {
    throw new Error("Method not implemented.");
  }
  LoadRecord(Id: number): void {
    throw new Error("Method not implemented.");
  }
  goEdit(): void {
    throw new Error("Method not implemented.");
  }
  cancelEdit(): void {
    throw new Error("Method not implemented.");
  }


  idRecurso = PermissionModule.Citas;
  idCaracteritica = CaracteristicasModule.CitasCanceladasInasistentes;

  @ViewChild("report", { static: false })
  private report: PrintButtonComponent;

  private unsubcribe$ = new Subject<void>();

  public override isNaN = isNaN;
  override icon = "";
  override FormRecord: UntypedFormGroup;
  override isLoading = false;
  maxDate = new Date();
  minFinalDate: Date;
  maxInicialDate: Date;
  override submitted = false;
  override showMessageProcess = false;
  override messageProcess = new SPlusProcessMessageComponent();
  reportPrint: ReportModel;
  override processType: ProcessType = ProcessType.create;
  tooltleError = "";

  @ViewChild("container", { read: ViewContainerRef })
  public containerRef: ViewContainerRef;


  entidadSelected: any;

  //listado de opciones --
  options: SPlusOption[] = [
    {
      id: "Ayuda",
      icon: "fa-solid fa-circle-question",
      text: "Ayuda",
      action: () => {
        this.OpenDocumentacion();
      },
    },
  ];

  constructor( 
  ) {
      super()
    
    this.loadFormGroup();
  }

  ngOnDestroy(): void {
    this.unsubcribe$.next();
    this.unsubcribe$.complete();
  }
  async ngOnInit(): Promise<void> {
    this.icon = this.route.snapshot.data["icon"];
    this.titleService.setTitle(this.route.snapshot.data["title"]);

    this.FormRecord.controls["fechaInicial"].valueChanges.pipe(takeUntil(this.unsubcribe$)).subscribe((s) => {

      var fechaFinal = this.FormRecord.controls["fechaFinal"].value;
      this.minFinalDate = s;
      if (fechaFinal) {
        var days = differenceInDays(fechaFinal, s);
        if (days < 0) {
          this.FormRecord.controls["fechaInicial"].setErrors({
            notValid: true,
          });
        }
      }

    });
    this.FormRecord.controls["fechaFinal"].valueChanges.pipe(takeUntil(this.unsubcribe$)).subscribe((s) => {
      var fechaInicial = this.FormRecord.controls["fechaInicial"].value;
      this.maxInicialDate = s;
      if (fechaInicial) {

        var days = differenceInDays(s, fechaInicial);
        if (days < 0) {
          this.FormRecord.controls["fechaFinal"].setErrors({
            notValid: true,
          });
        }
      }
    });
  }




  /**
   * Iniciar reporte
   * @param data
   */
  GetReport(data: boolean) {
    this.submitted = true;
    if (data) {

      if (this.FormRecord.valid && this.f.fechaInicial.value && this.f.fechaFinal.value) {
        this.LoadDataPrint();
        this.report.Init = true;
        this.report.Parameters = this.reportPrint;
        this.report.ngOnInit();
        this.report.OpenReport();
      }
    }
  }

  /**
   * Cargar datos para generar el reporte
   */
  LoadDataPrint() {
    this.submitted = true;

    this.reportPrint = new ReportModel("ListadoCitasCanceladasInasistentes");
    this.reportPrint.module = ReportModule.Citas;

    //parametros del reporteador
    this.reportPrint.options = {
      hideTool: true,
    };
    const fechaInicial = this.intl.formatDate(this.f.fechaInicial.value, "dd-MM-yyyy");
    const fechaFinal = this.intl.formatDate(this.f.fechaFinal.value, "dd-MM-yyyy");

    this.reportPrint.parameters = [
      { name: "fechaInicial", value: fechaInicial },
      { name: "fechaFinal", value: fechaFinal },
    ];

  }

  /**
   * Limpiar formulario
   */
  clearForm(): void {
    this.FormRecord.reset();
  }

  /**
   * Componentes del formulario
   */
  loadFormGroup() {
    this.FormRecord = new UntypedFormGroup({
      //Fecha Inicial
      fechaInicial: new TegettFormControl({
        label: "Fecha inicial",
        formState: "",
        validatorOrOpts: [Validators.required],
        required: true,
        nameControl: "fechaInicial"
      }),
      //Fecha Final
      fechaFinal: new TegettFormControl({
        label: "Fecha Final",
        validatorOrOpts: [Validators.required],
        required: true,
        nameControl: "fechaFinal"
      })

    });
  }

  /**
   * Abreviacion para acceder a los controles del FormRecord
   */
  public get f(): any {
    return this.FormRecord.controls;
  }

 
}
