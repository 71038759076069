<splus-forms id="ListadoPrenatalPage" [messageProcess]="messageProcess" [showMessageProcess]="showMessageProcess"
[FormRecord]="FormRecord" [submit]="submitted">

  <form autocomplete="off" [formGroup]="FormRecord">

      <splus-forms-main-title title="Reporte citas canceladas inasistentes" icon="fa-solid fa-file-contract">
      </splus-forms-main-title>

      <splus-forms-toolbar [options]="options">
          <splus-forms-toolbar-buttons>
              <core-form-print-button [idCaracteristica]="idCaracteritica" [idRecurso]="idRecurso" Tooltip="Generar"
                  [refreshInfo]="true" [Init]="false" #report (SendDataEmitter)="GetReport($event)" Color="primary"
                  Text="Generar" Icon="view_in_ar" IconColor="default" class="margin-right-20">
              </core-form-print-button>
              <button type="button" kendoButton rounded="small" themeColor="base" (click)="clearForm()">
                  Limpiar
              </button>
          </splus-forms-toolbar-buttons>
      </splus-forms-toolbar>
      <splus-forms-loading [loading]="isLoading"></splus-forms-loading>
      <splus-forms-fields [processType]="processType">
          <splus-forms-group [title]="'Filtros reporte'" #grupoFiltros>
              <splus-field *ngVar="f.fechaInicial as ctrl" [splusFormControl]="ctrl" #fecha>

                  <kendo-datepicker #calendar splusInput [navigation]="true"
                      [max]="maxInicialDate ? maxInicialDate : maxDate" format="dd/MM/yyyy" [formControl]="ctrl"
                      [splusField]="fecha" [value]="ctrl.value" [splusGroup]="grupoFiltros">
                      <ng-template kendoCalendarNavigationItemTemplate let-title>
                          {{isNaN(title) ? title : " Ene. " + title.substring( 2, 4 )}}
                      </ng-template>
                  </kendo-datepicker>

                  <ng-template splusFieldViewModeTemplate>
                      {{calendar.value | SPlusDate}}
                  </ng-template>

              </splus-field>
              <splus-field *ngVar="f.fechaFinal as ctrl" [splusFormControl]="ctrl" #fecha>

                  <kendo-datepicker #calendar splusInput [navigation]="true" [max]="maxDate" [min]="minFinalDate"
                      format="dd/MM/yyyy" [formControl]="ctrl" [splusField]="fecha" [value]="ctrl.value"
                      [splusGroup]="grupoFiltros">
                      <ng-template kendoCalendarNavigationItemTemplate let-title>
                          {{isNaN(title) ? title : " Ene. " + title.substring( 2, 4 )}}
                      </ng-template>
                  </kendo-datepicker>

                  <ng-template splusFieldViewModeTemplate>
                      {{calendar.value | SPlusDate}}
                  </ng-template>

              </splus-field>
          </splus-forms-group>

      </splus-forms-fields>
  </form>
</splus-forms>
